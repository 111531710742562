.page {
  display: flex;
  flex-direction: column;
  gap: 64px 0;
  padding-bottom: 80px;
  height: inherit;

  & h2 {
    margin-top: 0;
    margin-bottom: var(--spacing-5);
  }

  &.noBottomPadding {
    padding-bottom: 0;
  }

  @media (--tablet) {
    gap: 80px 0;

    & h2 {
      margin-bottom: var(--spacing-10);
    }
  }
}

.twoColLayoutReviews {
  display: flex;
  gap: 48px;

  & .contentColWithReviewsAds {
    max-width: 100%;
    @media (--desktop-l) {
      max-width: calc(100% - 348px);
    }
    & :global(.reviews-scrollbox) {
      @media (--desktop-l) {
        width: 100%;
        overflow-y: hidden;
      }
    }
  }

  & .adColReviews {
    display: none;

    @media (--desktop-l) {
      display: block;
      width: 300px;
    }
  }
}
