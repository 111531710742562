.mobileBanner {
  display: block;
  max-width: 100%;
  margin-bottom: var(--spacing-10);
  height: 250px;

  @media (--tablet) {
    display: none;
  }
}

.banner {
  display: none;
  @media (--tablet-l) {
    display: block;
    max-width: 100%;
  }
}

.sidebarAdWrapper {
  display: none;

  @media (--tablet-l) {
    display: flex;
    gap: var(--spacing-8);
    height: 100%;
    flex-direction: column;
  }
}

.reviewDmpuStickyAd1,
.reviewDmpuStickyAd2 {
  flex: 0 0 50%;

  @media (--tablet-l) {
    & > div {
      position: sticky;
      top: var(--sticky-info-height, 112px);
    }
  }
}

.leaderboard {
  display: none;
  @media (--tablet) {
    display: block;
  }
  @media (--tablet-l) {
    display: none;
  }
}

.floor {
  display: block;
  width: calc(100% + 40px);
  position: sticky;
  bottom: 0;
  z-index: 99;
  margin-left: -20px;

  [data-has-footer-sticky='true'] & {
    bottom: 52px;
    @media (--tablet-l) {
      bottom: 0;
    }
  }

  @media (min-width: 768px) {
    width: calc(100% + 48px);
    margin-left: -24px;
  }

  @media (min-width: 1024px) {
    display: none;
  }

  > div {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 50;
    padding: (--spacing-2) 0;
  }
}
